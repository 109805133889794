/* You can add global styles to this file, and also import other style files */
html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}
input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}
