@import "@angular/material/theming";
@include mat-core();

:root {
  --color-white: #ffffff;
  --color-dark: #263238;
  --color-dark-200: #313f47;
  --color-light: #37474f;
  --color-light-200: #6f8d9e;
  --color-main: #ba87f6;
  --color-main-dark: #5e5a81;
  --color-success: #6ef596;
  --color-success-dark: #54a86d;
  --color-warning: #c3a845;
  --color-warning-dark: #c3a845;
  --color-danger: #f54747;
  --color-danger-dark: #c23838;
  --color-info: #7d87fa;
  --color-info-dark: #454ead;
  --border-radius: 20px;
  --box-shadow: 0px 10px 32px 13px rgba(0, 0, 0, 0.1);
  --padding-card: 30px;
}

$indigo-palette: (
  50: #ba87f6,
  100: #ba87f6,
  200: #ba87f6,
  300: #ba87f6,
  400: #ba87f6,
  500: #ba87f6,
  600: #5e5a81,
  700: #5e5a81,
  800: #5e5a81,
  900: #5e5a81,
  contrast: (
    50: rgba(186, 135, 246, 1),
    100: rgba(186, 135, 246, 1),
    200: rgba(186, 135, 246, 1),
    300: rgba(186, 135, 246, 1),
    400: rgba(186, 135, 246, 1),
    500: rgba(186, 135, 246, 1),
    600: rgba(94, 90, 129, 1),
    700: rgba(94, 90, 129, 1),
    800: rgba(94, 90, 129, 1),
    900: rgba(94, 90, 129, 1)
  )
);

$primary: mat-palette($indigo-palette, 200);
$accent: mat-palette($mat-gray, 200);

$theme: mat-dark-theme($primary, $accent);

@include angular-material-theme($theme);
.card-save {
  background-color: var(--color-light);
  border-radius: var(--border-radius);
  padding: var(--padding-card);
  box-shadow: var(--box-shadow);
}
.round-save {
  border-radius: var(--border-radius);
}
.button-save {
  background-color: var(--color-main-dark);
  color: var(--color-white);
  outline: none;
  border: none;
  border-radius: var(--border-radius);
  padding: 7px 30px;
}
.fab-save {
  background-color: var(--color-main-dark) !important;
  color: var(--color-white) !important;
  box-shadow: none !important;
}
.mat-select-panel {
  background-color: var(--color-dark);
}
.mat-expansion-panel {
  background-color: var(--color-dark-200);
}
.mat-card {
  background-color: var(--color-dark);
  padding: var(--padding-card);
}
.mat-table {
  background-color: var(--color-dark);
}
.mat-table .mat-row:hover {
  background-color: var(--color-dark-200);
}

.mat-dialog-container {
  background-color: var(--color-dark-200);
}
.mat-calendar {
  background-color: var(--color-dark-200);
}
.mat-calendar-body-selected {
  color: var(--color-white);
}
.mat-paginator {
  background-color: var(--color-dark);
}
.mat-row-selected {
  background-color: var(--color-dark-200);
}
.mat-fab.mat-fab-save-success {
  background-color: var(--color-success-dark);
  color: var(--color-white);
}
.tooltip-save-success {
  background-color: var(--color-success-dark);
  color: var(--color-white);
}
.mat-chip {
  background-color: var(--color-dark);
}

.mat-chip[color="success"] {
  background-color: var(--color-success-dark);
}

.mat-chip[color="danger"] {
  background-color: var(--color-danger-dark);
}
// TODO: Hacer este metodo para todos los demas colores
*[color="main"],
.color-main {
  background-color: var(--color-main);
  color: var(--color-white);
}
*[color="main-dark"],
.color-main-dark {
  background-color: var(--color-main-dark) !important;
  color: var(--color-white) !important;
}
*[color="info"],
.color-info {
  background-color: var(--color-info);
  color: var(--color-white);
}
*[color="success"],
.color-success {
  background-color: var(--color-success);
  color: var(--color-white);
}
*[color="success-dark"],
.color-success-dark {
  background-color: var(--color-success-dark);
  color: var(--color-white);
}
*[color="warning"],
.color-warning {
  background-color: var(--color-warning);
  color: var(--color-white);
}
*[color="warning-dark"],
.color-warning-dark {
  background-color: var(--color-warning-dark);
  color: var(--color-white);
}
*[color="danger"],
.color-danger {
  background-color: var(--color-danger);
  color: var(--color-white);
}
*[color="danger-dark"],
.color-danger-dark {
  background-color: var(--color-danger-dark);
  color: var(--color-white);
}
