:root {
	--bg-main: #26282f;
	--bg-input: rgb(15, 15, 18);
	--border-main: #d7d7d7;
	--border-focus: #ff4500;
	--text-main: #fff;
	--bg-pink: #c2185b;
	--bg-gradient: linear-gradient(187deg, rgba(15, 15, 18, 1) 0%, rgba(19, 19, 23, 1) 50%, rgba(15, 15, 18, 1) 100%);
}
body {
	color: var(--text-main);
	font-size: 0.9rem;
}
.form-savetrack .form-savetrack__text,
.form-savetrack .form-savetrack__text:focus,
.form-savetrack .form-savetrack__text[type="checkbox"] {
	background-color: var(--bg-input);
	border-radius: 0;
	border: none;
	outline: none;
	box-shadow: none;
	border-bottom: 1px solid;
	border-color: var(--border-main);
	color: var(--text-main);
}
.form-savetrack input:focus {
	border-color: var(--border-focus);
}
.form-savetrack input[type="range"],
.form-savetrack input[type="range"]:focus {
	border: none;
}
.form-savetrack button {
	outline: none;
	border-radius: 0;
}
.form-savetrack select {
	background-color: var(--bg-input);
	color: #fff;
	border-radius: 0;
}
.form-savetrack select:focus-visible {
	color: #fff;
}
.card-savetrack {
	/* background-color: var(--bg-input); */
	padding: 20px;

	box-shadow: 1px 1px 5px 1px black;
	background: var(--bg-gradient);
	transition: all 0.3s;
}
.card-savetrack:hover {
	outline: 1px solid var(--bg-pink);
}

.table-savetrack {
	color: #fff;
	text-align: center;
	width: 100%;
}
.table-savetrack thead {
	background-color: #1b1c22;
}
.table-savetrack tbody tr {
	background-color: #212229;
	vertical-align: middle;
}
.table-savetrack .mat-sort-header-container {
	display: flex;
	justify-content: center;
}
.table-savetrack tbody tr:hover {
	background-color: #33363f;
	width: 100%;
}
/* Alerts customs type bootstrap */
.alert-savetrack {
	-webkit-transition: all 0.5s 0s ease;
	-moz-transition: all 0.5s 0s ease;
	-o-transition: all 0.5s 0s ease;
	transition: all 0.5s 0s ease;
}
.alert-savetrack:hover {
	box-shadow: 0px 0px 32px black inset;
}
.alert-savetrack * {
	transition: all 0.5s 0s ease;
}
.alert-savetrack:hover * {
	transform: scale(1.1);
}
.alert-savetrack-warning {
	color: #966715;
	background-color: #f6a821;
}
.alert-savetrack-warning-high {
	color: #802909;
	background-color: #ff4500;
}
.mat-cell {
	padding-left: 10px !important;
	padding-right: 10px !important;
}
