body {
	background: var(--color-dark);
}
button {
	outline: none !important;
}
.main-wrapper {
	padding-left: 230px;
	padding-right: 30px;
}
.main-wrapper--extended {
	padding-left: 120px;
}
agm-map {
	width: 100%;
	height: 300px;
}
.header-sticky thead {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: #1b1c22;
	z-index: 100;
}
/* misc */
.cursor-pointer {
	cursor: pointer;
}
.form-button-float-top {
	position: relative;
}
.mat-table-container {
	max-height: 600px;
	overflow-y: auto;
	overflow-x: hidden;
}
button.fab-fixed {
	position: fixed;
	right: 30px;
	bottom: 30px;
}
.fab-fixed-list {
	position: fixed;
	right: 30px;
	bottom: 30px;
}
.fab-fixed-list-vertical {
	display: flex;
	flex-direction: column;
	gap: 7px;
}
